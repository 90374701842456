import * as React from "react"
import { FC } from "react"
import styled from "styled-components"
import { HEADER_HEIGHT } from "./Header"
import { zIndexs } from "@social-supermarket/social-supermarket-components"

const Container = styled.div`
  background-color: white;
  width: 100vw;
  position: fixed !important;
  top: ${HEADER_HEIGHT}px !important;
  z-index: ${zIndexs.subMenuBarZIndex};
  height: 50px;
`

interface Props {
  children: React.ReactNode
  className?: string
}

const SubMenuBar: FC<Props> = ({ children, className }) => {
  return <Container className={className}>{children}</Container>
}

export default SubMenuBar
